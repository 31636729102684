<template>
  <section class="section">
    <div class="container">
      <b-button
        type="is-primary"
        icon-left="format-list-bulleted"
        @click="openEinvReports"
        >{{ $t("buttons.goto_einv_report") }}</b-button
      >
    </div>
    <div class="container">
      <b-loading :active="isLoadingChart" :is-full-page="false"></b-loading>
      <div class="block mt-5">
        <b-field :label="$t('einvoice.select-date-range')" grouped horizontal>
          <b-datepicker
            range
            icon="calendar-today"
            v-model="datesFilter"
            :max-date="new Date()"
          >
          </b-datepicker>
          <p class="control">
            <b-button class="button is-primary" @click="getStatusData">{{
              $t("search")
            }}</b-button>
          </p>
        </b-field>
      </div>
      <Chart
        class="chart-container"
        type="horizontalBar"
        :data="stackedBarData"
        :options="stackedOptions"
      ></Chart>
    </div>
  </section>
</template>

<script>
import Chart from "primevue/chart";
import { einvoiceService } from "@/services";
import { subDays, addDays } from "date-fns";
import { toStringISODate } from "../helpers/date";

export default {
  components: {
    Chart,
  },
  data() {
    return {
      isLoadingChart: false,
      datesFilter: [subDays(Date.now(), 15), new Date()],
      statusData: [],
      stackedOptions: {
        maintainAspectRatio: false, // NEVER SET IT TO TRUE!!!
        tooltips: {
          mode: "index",
          intersect: false,
        },
        responsive: true,
        scales: {
          xAxes: [
            {
              stacked: true,
              //type: 'logarithmic'
            },
          ],
          yAxes: [
            {
              stacked: true,
            },
          ],
        },
      },
    };
  },
  computed: {
    /*isLoading: function () {
      return false;
    },*/
    companyName: function () {
      return this.$route.params.company;
    },
    stackedBarData: function () {
      const labels = [];
      const datasetsObj = {
        OK: {
          label: this.$t("einvoice.status.options.OK"),
          backgroundColor: "#00FF00",
          data: [],
        },
        WAITING: {
          label: this.$t("einvoice.status.options.WAITING"),
          backgroundColor: "#FFFF00",
          data: [],
        },
        EXPIRING: {
          label: this.$t("einvoice.status.options.EXPIRING"),
          backgroundColor: "#FFA500",
          data: [],
        },
        WARNING: {
          label: this.$t("einvoice.status.options.WARNING"),
          backgroundColor: "#E500FF",
          data: [],
        },
        ERROR: {
          label: this.$t("einvoice.status.options.ERROR"),
          backgroundColor: "#FF0000",
          data: [],
        },
        SOLVED: {
          label: this.$t("einvoice.status.options.SOLVED"),
          backgroundColor: "#4F92FF",
          data: [],
        },
      };

      this.statusData.forEach((element) => {
        if (element.totalValues > 0) {
          labels.push(element.className);
          element.status.forEach((st) => {
            datasetsObj[st.id].data.push(st.value);
          });
        }
      });

      const data = {
        labels: labels,
        datasets: Object.values(datasetsObj),
      };

      return data;
    },
  },
  /*watch: {
    stackedBarData() {
      this.$refs.stackChart.refresh();
    }
  },*/
  methods: {
    openEinvReports() {
      window.open(this.$config.urls.EINV_REPORT_URL);
    },
    getStatusData() {
      this.isLoadingChart = true;
      const minDatetimeIncl = toStringISODate(this.datesFilter[0]);
      const maxDatetimeExcl = toStringISODate(addDays(this.datesFilter[1], 1));
      einvoiceService
        .getStatus(
          this.companyName,
          null,
          minDatetimeIncl,
          maxDatetimeExcl,
          null
        )
        .then((res) => (this.statusData = res.clazz))
        .finally(() => (this.isLoadingChart = false));
    },
  },
  mounted() {
    this.getStatusData();
  },
  created() {},
};
</script>

<style lang="scss" scoped>
/*
@import "~primevue/resources/themes/saga-blue/theme.css"; //theme
@import "~primevue/resources/primevue.min.css"; //core css
@import "~primeicons/primeicons.css"; //icons
*/
.chart-container {
  min-height: 85vh;
}
</style>
